import { cn } from '@/services/Methods/normalMethods';
import { useRouter } from 'next/navigation'

const ButtonComponent = ({ id = "", children, type = 'button', onClick, link, classes, disabled = false, conditionClass }) => {
  const router = useRouter();

  return (
    <button
      type={type}
      id={id}
      disabled={disabled}
      className={cn(`xl:mb-4 lg:mb-3 md:mb-2 sm:mb-1.5 mb-3 bg-black text-white f-q-regular font-medium text-[16px] w-full rounded-[0px] hover:bg-opacity-90 transition-all hover:bg-white duration-200 pt-[12px] pb-[10px] no-underline px-[20px] border-[1px]    hover:border-black
       hover:text-black hover:bg-transparent hover:border-solid hover:border-[1px]  btn-submit_n`, classes, conditionClass || {})}

      {...(type !== "submit") ? {
        onClick: () => {
          if (link) {
            router.push(link);
          }
          if (onClick) {
            onClick()
          }
        }
      } : {}}
    >
      {children}
    </button>
  );
};

export default ButtonComponent;
